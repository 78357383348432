<template>
  <div class="device-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form">
            <el-select
              v-if="deviceid == 0"
              v-model="listfilter.deviceid"
              filterable
              remote
              reserve-keyword
              default-first-option
              clearable
              placeholder="请选择监测设备"
              size="medium"
              :remote-method="getDeviceList"
              :loading="getDeviceLoading"
              @change="onSearch"
            >
              <el-option
                v-for="item in deviceSelect"
                :key="item.Id"
                :label="item.DeviceName"
                :value="item.Id"
              >
              </el-option>
            </el-select>

            <el-date-picker
              v-model="filterDaterange"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="medium"
              style="width: 240px; margin-left: 10px"
              value-format="yyyy-MM-dd"
              @change="dateChange"
            >
            </el-date-picker>
            <el-button
                style="margin-left: 10px"
                type="primary"
                size="medium"
                icon="el-icon-search"
                @click="onSearch"
                >查询</el-button
            >
          </el-form>
        </div>
        <div class="action-right">
          <el-button
            type="primary"
            icon="el-icon-download"
            size="medium"
            @click="onAdd"
            >下发命令</el-button
          >
        </div>
      </div>
    </div>

    <el-table
      class="list-table"
      ref="multipleTable"
      :data="listData"
      tooltip-effect="dark"
      style="width: 100%"
      :stripe="true"
      :border="false"
      v-loading="tableLoading"
    >
      <el-table-column label="设备名称" prop="DeviceName"></el-table-column>
      <el-table-column label="设备IEMI" prop="IEMI"></el-table-column>
      <el-table-column label="命令名称" prop="Name"></el-table-column>
      <el-table-column label="命令内容" prop="Command"></el-table-column>
      <el-table-column label="发送状态" prop="IsSend">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.IsSend == true" effect="dark" size="small"
            >已发送</el-tag
          >
          <el-tag v-else type="danger" effect="dark" size="small"
            >未发送</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="执行状态" prop="Success">
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.Success == 1"
            type="info"
            effect="dark"
            size="small"
            >未执行</el-tag
          >
          <el-tag
            v-if="scope.row.Success == 2"
            type="danger"
            effect="dark"
            size="small"
            >执行失败</el-tag
          >
          <el-tag v-if="scope.row.Success == 0" effect="dark" size="small"
            >执行成功</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="CreateTime" width="100">
        <template slot-scope="scope">
          {{ scope.row.CreateTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="反馈时间" prop="ReceiptTime" width="100">
        <template slot-scope="scope">
          {{ scope.row.ReceiptTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            type="warning"
            icon="el-icon-error"
            size="mini"
            style="margin-left: 10px"
            @click="onListDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      layout="prev, pager, next"
      prev-text="上一页"
      next-text="下一页"
      class="list-pagination"
      :page-size="page.pagesize"
      hide-on-single-page
      :total="page.total"
      :current-page.sync="page.pageindex"
      @current-change="onPageChange"
    >
    </el-pagination>

    <el-dialog
      title="下发命令"
      :visible.sync="dialogFormVisible"
      width="750px"
      custom-class="geology-dialog"
      :modal-append-to-body="true"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="form"
        class="geology-form"
        :rules="formRules"
        :model="form"
        label-width="120px"
        style="padding-top: 20px"
      >
        <el-row>
          <el-col :span="10" :offset="1" v-if="deviceid == 0">
            <el-form-item label="设备" prop="DeviceId">
              <el-select
                v-model="form.DeviceId"
                filterable
                remote
                reserve-keyword
                default-first-option
                clearable
                placeholder="请选择监测设备"
                size="medium"
                :remote-method="getDeviceList"
                :loading="getDeviceLoading"
              >
                <el-option
                  v-for="item in deviceSelect"
                  :key="item.Id"
                  :label="item.DeviceName"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="推送方式" prop="SendType">
              <el-select
                v-model="form.SendType"
                placeholder="请选择推送方式"
                size="medium"
              >
                <el-option label="tcp" value="0"> </el-option>
                <el-option label="mqtt" value="1"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="快捷选择">
              <el-select
                  v-model="commandType"
                  size="small"
                  placeholder="快捷命令"
                  style="width: 100%"
                  @change="onCommandTypeChange"
                >
                  <el-option
                    v-for="item in CommandTypeData"
                    :key="item.Id"
                    :label="item.ItemName"
                    :value="item.Id"
                  >
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21" :offset="1">
            <el-form-item label="命令名称" prop="Name">
              <el-input v-model="form.Name" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21" :offset="1">
            <el-form-item label="命令内容" prop="Command">
              <el-input
                type="textarea"
                v-model="form.Command"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23" :offset="1">
            <el-form-item>
              <el-button type="primary" size="medium" @click="onSubmit"
                >立即下发</el-button
              >
              <el-button
                type="primary"
                plain
                size="medium"
                style="margin-left: 10px"
                @click="dialogFormVisible = false"
                >取消</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  getDeviceList,
  getDeviceCommandList,
  addDeviceCommand,
  delDeviceCommand,
} from "@/api/device";
export default {
  data() {
    return {
      listData: [],
      tableLoading: false,

      filterDaterange: "",
      getDeviceLoading: false,
      deviceSelect: [],

      listfilter: {
        deviceid: "",
        fstarttime: "",
        fendtime: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },

      dialogFormVisible: false,
      CommandTypeData:[],
      commandType:'',
      form: {
        DeviceId: "",
        Name: "",
        Command: "",
        SendType: "",
      },
      formRules: {
        DeviceId: [{ required: true, trigger: "blur", message: "请选择设备" }],
        SendType: [
          { required: true, trigger: "blur", message: "请选择发送方式" },
        ],
        Name: [{ required: true, trigger: "blur", message: "请选择执行命令" }],
        Command: [
          { required: true, trigger: "blur", message: "请输入命令内容" },
        ],
      },
    };
  },
  props: {
    deviceid: {
      type: [Number,String],
      default: 0,
    },
  },
  components: {
  },
  methods: {
    getDeviceList(value) {
      this.getDeviceLoading = true;
      getDeviceList(1, 10, {
        fname: value,
      }).then((resdata) => {
        this.getDeviceLoading = false;
        if (resdata.code == 0) {
          this.deviceSelect = resdata.data.data;
        }
      });
    },
    dateChange(value) {
      if (value) {
        var newdate = [];
        for (let index = 0; index < this.filterDaterange.length; index++) {
          const element = this.filterDaterange[index];
          newdate.push(element);
        }
        this.listfilter.fstarttime = newdate[0];
        this.listfilter.fendtime = newdate[1];
        this.getListData();
      } else {
        this.listfilter.fstarttime = "";
        this.listfilter.fendtime = "";
        this.getListData();
      }
    },
    onSearch() {
      this.page.pageindex = 1;
      this.getListData();
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      getDeviceCommandList(
        this.page.pageindex,
        this.page.pagesize,
        this.listfilter
      )
        .then((resdata) => {
          this.tableLoading = false;
          if (resdata.code == 0) {
            this.listData = resdata.data.data;
            this.page.total = resdata.data.count * 1;
          } else {
            this.$message({
              type: "error",
              message: resdata.msg,
            });
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    onListDel(item) {
      this.$confirm("确定要删除选择的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delDeviceCommand(item.Id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功!");
                this.getListData();
              } else {
                this.$message.error("删除失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error(errmsg);
            });
        })
        .catch(() => {});
    },
    onAdd() {
      this.form = {
        DeviceId: this.deviceid>0 ? this.deviceid : "",
        Name: "",
        Command: "",
        SendType: "",
      };
      this.commandType="";
      this.dialogFormVisible = true;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          addDeviceCommand(this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("保存成功!");
                this.getListData();
                this.dialogFormVisible = false;
              } else {
                this.$message.error("保存失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error("保存失败!" + errmsg);
            });
        } else {
          return false;
        }
      });
    },
    onCommandTypeChange(value){
        var nowCommand = this.CommandTypeData.filter(item=>item.Id==value);
        if(nowCommand.length>0){
            this.form.Name = nowCommand[0].ItemName;
            this.form.Command = nowCommand[0].ItemValue;
        }
      
    }
  },
  created() {
    if(this.deviceid>0){
      this.listfilter.deviceid = this.deviceid;
    }
    this.$store.dispatch("dictionary/getCommandType").then((data) => {
      this.CommandTypeData = data;
    });
    this.getDeviceList();
    this.getListData();
  },
};
</script>